<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-card>
        <v-card-title>
          Aperçu
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">
          <div class="email-wrapper">
            <div class="header" :style="{'backgroundColor' : template.header_bg_color}">

              <h1 :style="{'color' : template.header_text_color}" v-if="template.header_content_type === 'text'">
                {{ template.header_text }}
              </h1>

              <img v-if="template.header_content_type === 'logo'"
                   :src="fileUrl + template.logo"
                   width="130"
                   alt="Logo">

            </div>

            <img v-if="template.hero_image"
                 :src="fileUrl + template.hero_image"
                 alt="Hero Image"
                 class="hero-image">

            <div class="content" v-html="template.body"></div>
            <div class="footer" v-html="template.footer_text"></div>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILE_URL,
      dialog: false,
      template: {},
    }
  },
  methods: {
    open(template) {
      this.dialog = true
      this.template = template
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style>
.email-wrapper {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
}

.header {
  padding: 25px;
  text-align: center;
}

.header h1 {
  margin: 0;
  font-size: 28px;
}

.hero-image {
  width: 100%;
  height: auto;
  display: block;
}

.content {
  padding: 20px;
}


.footer {
  background-color: #f9f9f9;
  text-align: center;
  padding: 15px;
  font-size: 14px;
  color: #666;
}

.footer a {
  text-decoration: none;
}

@media (max-width: 600px) {
  .email-wrapper {
    width: 100% !important;
    border-radius: 0;
  }

  .header h1 {
    font-size: 24px;
  }

  .footer {
    font-size: 12px;
    padding: 10px;
  }
}
</style>