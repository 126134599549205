<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-card :loading="fileLoading" :disabled="fileLoading">
        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">


          <v-checkbox v-model="form.is_default"
                      label="Modèle par défaut"
                      :error-messages="errors.is_default"
          ></v-checkbox>


          <v-text-field outlined
                        dense
                        v-model="form.title"
                        :error-messages="errors.title"
                        label="Nom de modèle *"
          />


          <v-text-field outlined
                        dense
                        v-model="form.subject"
                        :error-messages="errors.subject"
                        label="Sujet *"
          />


          <v-card outlined rounded class="mb-5">

            <h4 class="font-weight-medium pa-4 grey lighten-5">En-tête</h4>

            <v-divider/>

            <v-card-text>

              <v-text-field outlined
                            type="color"
                            hide-details="auto"
                            dense
                            v-model="form.header_bg_color"
                            :error-messages="errors.header_bg_color"
                            label="Couleur de l'arrière plan *"
              />

              <v-radio-group row label="Type de contenu" v-model="form.header_content_type">
                <v-radio value="text" label="Text"></v-radio>
                <v-radio value="logo" label="Logo"></v-radio>
              </v-radio-group>

              <v-file-input outlined
                            @change="uploadImage($event,'logo')"
                            :loading="fileLoading"
                            v-if="form.header_content_type === 'logo'"
                            dense
                            :error-messages="errors.logo"
                            label="Logo à afficher dans l'en-tête *"
                            accept="image/*"
              />

              <div v-if="form.logo && form.header_content_type === 'logo'"
                   class="rounded-lg grey lighten-5">
                <div class="pa-2 d-flex align-center justify-space-between">
                  <v-avatar rounded>
                    <v-img :src="fileUrl + form.logo"></v-img>
                  </v-avatar>
                  <v-spacer></v-spacer>
                  <v-icon color="red" @click="form.logo = ''">mdi-close</v-icon>
                </div>
              </div>

              <v-text-field outlined
                            v-if="form.header_content_type === 'text'"
                            dense
                            v-model="form.header_text"
                            :error-messages="errors.header_text"
                            label="Texte à afficher dans l'en-tête *"
              />

              <v-text-field outlined
                            v-if="form.header_content_type === 'text'"
                            type="color"
                            hide-details="auto"
                            dense
                            v-model="form.header_text_color"
                            :error-messages="errors.header_text_color"
                            label="Couleur de text *"
              />


            </v-card-text>
          </v-card>

          <v-card outlined rounded class="mb-5">

            <h4 class="font-weight-medium pa-4 grey lighten-5">Contenu</h4>

            <v-divider/>

            <v-card-text>

              <v-file-input outlined
                            @change="uploadImage($event,'hero_image')"
                            :loading="fileLoading"
                            suffix="700x300 px"
                            accept="image/*"
                            dense
                            :error-messages="errors.hero_image"
                            label="Une image de héros (optionnel)"
              />

              <div v-if="form.hero_image"
                   class="rounded-lg grey lighten-5 mb-5">
                <div class="pa-2 d-flex align-center justify-space-between">
                  <v-avatar rounded>
                    <v-img :src="fileUrl + form.hero_image"></v-img>
                  </v-avatar>
                  <v-spacer></v-spacer>
                  <v-icon color="red" @click="form.hero_image = ''">mdi-close</v-icon>
                </div>
              </div>


              <v-expansion-panels flat class="rounded mb-3 grey lighten-5">
                <v-expansion-panel class="grey lighten-5">
                  <v-expansion-panel-header>
                    <div>
                      <v-icon left color="info">mdi-information</v-icon>
                      Utilisez les variables suivantes pour personnaliser votre message :
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="f-14 grey--text text--darken-1">
                      <ul>
                        <li>
                          <v-chip small dark color="info" draggable @click="copyToClipboard('@password')">@password
                          </v-chip>
                          remplace le mot de passe
                        </li>
                        <li class="py-2">
                          <v-chip small dark color="info" draggable @click="copyToClipboard('@name')">@name</v-chip>
                          remplace le nom d'utilisateur
                        </li>
                        <li class="mb-2">
                          <v-chip small dark color="info" draggable @click="copyToClipboard('@phone')">@phone</v-chip>
                          remplace le numéro de téléphone
                        </li>
                        <li>
                          <v-chip small dark color="info" draggable @click="copyToClipboard('@email')">@email</v-chip>
                          remplace l'adresse e-mail
                        </li>
                      </ul>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <quill-editor v-model="form.body"
                            :options="editorOptions"
                            class="custom-editor"
              ></quill-editor>
              <small class="red--text mt-3" v-if="errors && errors.body">
                {{ errors.body.toString() }}
              </small>
            </v-card-text>
          </v-card>

          <v-card outlined rounded>

            <h4 class="font-weight-medium pa-4 grey lighten-5">Bas de page (signature)</h4>

            <v-divider/>

            <v-card-text>
              <quill-editor v-model="form.footer_text"
                            :options="editorOptions"
                            class="custom-editor"
              ></quill-editor>
              <small class="red--text mt-3" v-if="errors && errors.footer_text">
                {{ errors.footer.toString() }}
              </small>
            </v-card-text>
          </v-card>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$refs.previewEmailDialog.open(form)">
            <v-icon left>mdi-eye</v-icon>
            Aperçu
          </v-btn>
          <v-btn class="ml-2"
                 :loading="isLoading"
                 :disabled="isLoading"
                 color="primary"
                 @click="handleSave"
                 depressed
          >
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <PreviewEmailDialog ref="previewEmailDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import PreviewEmailDialog from "./PreviewEmailDialog";
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ImageResize from 'quill-image-resize-vue';
import {quillEditor} from 'vue-quill-editor'
import Quill from 'quill';

Quill.register('modules/imageResize', ImageResize);

export default {
  components: {
    quillEditor,
    PreviewEmailDialog,
  },
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILE_URL,
      title: null,
      dialog: false,
      fileLoading: false,
      isLoading: false,
      form: {
        is_default: false,

        title: '',
        subject: '',

        header_content_type: 'text',
        header_bg_color: '#FFFFFF',
        header_text_color: '#201f1f',
        header_text: '',
        logo: '',

        hero_image: '',
        body: '',

        footer_text: '',
      },
      errors: {},

      editorOptions: {
        placeholder: '',
        modules: {
          toolbar: [
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'align': []}],
            ['link', 'image'],
            [{'color': []}, {'background': []}],
            [{'font': []}],
            [{'indent': '-1'}, {'indent': '+1'}],
            ['clean']
          ],
          imageResize: {
            displaySize: true
          }
        }
      }
    }
  },
  methods: {
    open(template) {
      this.dialog = true
      this.errors = {}
      this.title = template ? "Modifier un modèle" : "Ajouter un modèle"
      this.form = template ?? {
        is_default: false,
        title: '',
        subject: '',

        header_content_type: 'text',
        header_bg_color: '#FFFFFF',
        header_text_color: '#201F1F',
        header_text: '',
        logo: '',

        hero_image: '',
        body: '',

        footer_text: '',
      }
    },
    close() {
      this.dialog = false
    },
    handleSave() {
      this.errors = {}
      this.isLoading = true
      let url = this.form.id ? "/v1/email-templates/update" : "/v1/email-templates/store"
      HTTP.post(url, this.form).then(() => {
        this.isLoading = false
        this.$successMessage = "Cet élément a été enregistré avec succès.";
        this.close();
        this.$emit("refresh");
      }).catch(err => {
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
        this.isLoading = false
        console.log(err)
      })
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$successMessage = "Copié dans le presse-papiers!"
    },
    uploadImage(file, fileName) {
      if (file) {
        this.fileLoading = true

        let formData = new FormData()
        formData.append('image', file)

        HTTP.post("/v1/email-templates/upload-image", formData).then((res) => {
          this.fileLoading = false
          this.form[fileName] = res.data.data
        }).catch(err => {
          this.$errorMessage = "Une erreur s'est produite veuillez corriger. changer l'image et ressay!";
          this.fileLoading = false
          console.log(err)
        })
      }
    },
  },
}
</script>

<style>
.custom-editor .ql-editor {
  min-height: 150px;
}
</style>